// Imports: Components
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// Component: Nav Bar
const NavBar: React.FC = (): JSX.Element => {
  // Data: Resume PDF
  const data = useStaticQuery(graphql`
    {
      pdf: file(name: { eq: "JeffLewisResume_03.30.2021" }) {
        name
        extension
        publicURL
      }
    }
  `);

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div id="navMenu" className="navbar-menu">
          <Link className="navbar-item" to="/">Home</Link>

          <Link className="navbar-item" to="/about/">About</Link>

          <Link className="navbar-item" to="/projects/">Projects</Link>

          <a
            className="navbar-item"
            href={data.pdf.publicURL}
            type="application/pdf"
            target="_blank"
            rel="noopener noreferrer"
          >Resume</a>
        </div>
      </div>
    </nav>
  );
};

// Exports
export default NavBar;
