// Imports: Dependencies
import React from 'react';
import { FaGithub, FaNpm, FaMediumM, FaLinkedin, FaRegEnvelope } from 'react-icons/fa';
import { TiSocialInstagram } from 'react-icons/ti';

// Component: Footer
const Footer: React.FC = (): JSX.Element => {
  return (
    <div id="contact-container">
      <div className="contact-item">
        <a id="github" href="https://github.com/jefelewis" target="_blank" rel="noopener noreferrer">
          <FaGithub
            className="icon"
            color="black"
          />
        </a>
      </div>

      <div className="contact-item">
        <a id="npm" href="https://www.npmjs.com/~jefelewis" target="_blank" rel="noopener noreferrer">
          <FaNpm
            className="npm-icon"
            color="black"
          />
        </a>
      </div>

      <div className="contact-item">
        <a id="medium" href="https://medium.com/@jeffrey.allen.lewis" target="_blank" rel="noopener noreferrer">
          <FaMediumM
            className="icon"
            color="black"
          />
        </a>
      </div>

      <div className="contact-item">
        <a id="instagram" href="http://instagram.com/jefe_lewis" target="_blank" rel="noopener noreferrer">
          <TiSocialInstagram
            className="instagram-icon"
            color="black"
          />
        </a>
      </div>

      <div className="contact-item">
        <a id="linkedin" href="http://www.linkedin.com/in/jeff-allen-lewis" target="_blank" rel="noopener noreferrer">
          <FaLinkedin
            className="icon"
            color="black"
          />
        </a>
      </div>

      <div className="contact-item">
        <a id="envelope" href="mailto:jeffrey.allen.lewis@gmail.com" target="_blank" rel="noopener noreferrer">
          <FaRegEnvelope
            className="icon"
            color="black"
          />
        </a>
      </div>
    </div>
  );
};

// Exports
export default Footer;
