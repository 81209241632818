// Imports: Dependencies
import * as React from 'react';
import { Container } from 'react-bootstrap';

// Imports: CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/404.css';
import '../css/NavBar.css';
import '../css/Hero.css';
import '../css/Footer.css';
import '../css/Project.css';
import '../css/About.css';
import '../css/Phone.css';
import '../css/Web.css';
import '../css/MacbookPro.css';
import '../css/TextFade.css';
import '../css/TextFade2.css';

// Layout
const Layout: React.FC = ({ children }): JSX.Element => {
  return (
    <div className="layout">
      <Container>
        <main>{children}</main>
      </Container>
    </div>
  );
};

// Exports
export default Layout;
